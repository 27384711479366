// @flow
'use strict'

import * as React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useAuth } from '@oneblink/apps-react'

import { profileMenuItem } from 'services/menu-items-service'
import BreadCrumbs from 'components/BreadCrumbs'
import { FormProvider } from 'hooks/useForm'
import HomeScreenName from 'components/HomeScreenName'
import { MaterialIcon } from 'components'

type Props = {
  children: React.ReactNode
}

function HeaderNavigation({ children }: Props) {
  const { isUsingFormsKey } = useAuth()
  const isHidingMenu = isUsingFormsKey

  return (
    <FormProvider>
      <div
        className={clsx('ob-header-navigation', {
          'has-hidden-navigation': isHidingMenu,
        })}
      >
        <div className="ob-header-navigation__navbar">
          <div className="ob-header-navigation__navbar-content container">
            <div className="ob-header-navigation__breadcrumbs">
              <div className="ob-header-navigation__home">
                <Link
                  className="button is-rounded ob-header-navigation__button ob-header-navigation__home-button"
                  to="/"
                >
                  <span className="icon is-small">
                    <MaterialIcon
                      className="ob-header-navigation__button-icon"
                      aria-hidden={false}
                    >
                      home
                    </MaterialIcon>
                  </span>
                </Link>
              </div>
              <BreadCrumbs></BreadCrumbs>
            </div>
            <div className="ob-header-navigation__title-section">
              <HomeScreenName></HomeScreenName>
              {profileMenuItem && (
                <div className="ob-header-navigation__profile">
                  <Link
                    className="button is-rounded ob-header-navigation__button ob-header-navigation__profile-button"
                    to={profileMenuItem.href}
                  >
                    <span className="icon is-small">
                      <MaterialIcon className="ob-header-navigation__button-icon">
                        {profileMenuItem.icon ? profileMenuItem.icon : 'person'}
                      </MaterialIcon>
                    </span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="ob-header-navigation__content">{children}</div>
      </div>
    </FormProvider>
  )
}

export default React.memo<Props>(HeaderNavigation)
