import * as React from 'react'
import clsx from 'clsx'
import { OneBlinkAppsError, authService, autoSaveService } from '@oneblink/apps'
import {
  useNullableState,
  useIsMounted,
  useBooleanState,
  useAuth,
  useLoadResourcesState,
} from '@oneblink/apps-react'
import ErrorMessage from '@oneblink/apps-react/dist/components/messages/ErrorMessage'

import profileImage from 'images/profile.png'
import { jobsLabel, profileLabel } from 'services/menu-items-service'
import { ErrorModal, Modal, LoginSignupButtons, MaterialIcon } from 'components'
import useIsAuthorised from 'hooks/useIsAuthorised'
import useNotificationsService from 'hooks/useNotifications'
import { menuItems } from '../services/menu-items-service'

function Profile() {
  const isMounted = useIsMounted()
  const [isSubscribing, setIsSubscribing] = React.useState(false)
  const [error, setError, clearError] =
    useNullableState<OneBlinkAppsError>(null)

  const isAuthorised = useIsAuthorised()
  const notifications = useNotificationsService()
  const { userFriendlyName, userProfile, isLoggedIn } = useAuth()
  const [isConfirmingLogout, confirmLogout, cancelLogout] =
    useBooleanState(false)

  const handleLogout = React.useCallback(async () => {
    try {
      authService.logoutHostedUI()
    } catch (error) {
      setError(error as OneBlinkAppsError)
    } finally {
      cancelLogout()
    }
  }, [cancelLogout, setError])

  const toggleSubscription = React.useCallback(async () => {
    setIsSubscribing(true)
    try {
      if (notifications.isSubscribed) {
        await notifications.unsubscribe()
      } else {
        await notifications.subscribe()
      }
    } catch (error) {
      if (isMounted.current) {
        setError(error as OneBlinkAppsError)
      }
    }
    if (isMounted.current) {
      setIsSubscribing(false)
    }
  }, [isMounted, notifications, setError])

  const [
    isConfirmingDeleteAutoSave,
    confirmDeleteAutoSave,
    cancelDeleteAutoSave,
  ] = useBooleanState(false)
  const [
    autoSaveKeys,
    isLoadingAutoSaveKeys,
    errorLoadingAutoSaveKeys,
    loadAutoSaveKeysAgain,
    setAutoSaveKeys,
  ] = useLoadResourcesState(autoSaveService.getAutoSaveKeys)
  const [isDeletingAutoSave, startDeletingAutoSave, stopDeletingAutoSave] =
    useBooleanState(false)

  const handleDeleteAutoSave = React.useCallback(async () => {
    startDeletingAutoSave()
    try {
      await autoSaveService.deleteAllAutosaveData()
      setAutoSaveKeys([])
    } catch (error) {
      setError(error as OneBlinkAppsError)
    } finally {
      stopDeletingAutoSave()
      cancelDeleteAutoSave()
    }
  }, [
    cancelDeleteAutoSave,
    setAutoSaveKeys,
    setError,
    startDeletingAutoSave,
    stopDeletingAutoSave,
  ])

  return (
    <div className="ob-profile section is-mobile-section">
      <div className="container">
        {!isLoggedIn && (
          <section>
            <div className="ob-header has-margin-bottom-4">
              <h1 className="title is-1 is-size-3-mobile ob-header__heading">
                {profileLabel}
              </h1>
            </div>
            <div className="ob-login__container">
              <div className="has-text-centered has-margin-bottom-8">
                <MaterialIcon className="has-text-grey icon-x-large">
                  lock
                </MaterialIcon>
              </div>
              <p className="cypress-login-required ob-text__login-required has-margin-bottom-4">
                You need to be logged in to view your {profileLabel}.
              </p>

              <LoginSignupButtons />
            </div>
          </section>
        )}

        {isLoggedIn && (
          <section>
            <div className="ob-user-profile">
              <figure className="image is-128x128 ob-user-profile__figure">
                <img
                  className="is-rounded ob-user-profile__image"
                  alt="profile-picture"
                  src={(userProfile && userProfile.picture) || profileImage}
                />
              </figure>
              <h5 className="title is-5 has-margin-top-6">
                {userFriendlyName}
              </h5>
              <button
                type="button"
                className="button ob-button ob-button__logout cypress-logout-button"
                onClick={confirmLogout}
              >
                Logout
              </button>
            </div>

            {isAuthorised && (
              <div>
                {menuItems.find((menuItem) => menuItem.type === 'JOBS') && (
                  <div>
                    <div className="has-margin-bottom-7 has-margin-top-4">
                      <h3 className="title is-3">Notifications</h3>
                    </div>
                    <p className="has-margin-bottom-7">
                      {notifications.isSubscribed ? (
                        <>
                          You have successfully subscribed to receive push
                          notifications when you are assigned new {jobsLabel}.
                          Click the <b>Unsubscribe</b> button to stop receiving
                          notifications on this device.
                        </>
                      ) : (
                        <>
                          Click the <b>Subscribe</b> button to receive
                          notifications when you are assigned new {jobsLabel}.
                          Note, you may be prompted for permission by your
                          device.
                        </>
                      )}
                    </p>
                    <button
                      type="button"
                      className={clsx('button ob-button ob-button__subscribe', {
                        'is-primary': !notifications.isSubscribed,
                      })}
                      disabled={isSubscribing}
                      onClick={toggleSubscription}
                    >
                      {notifications.isSubscribed ? 'Unsubscribe' : 'Subscribe'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </section>
        )}

        <ErrorModal error={error} onClose={clearError} />

        <div>
          <div className="has-margin-bottom-7 has-margin-top-4">
            <h3 className="title is-3">In Progress Form Submissions</h3>
          </div>

          <p className="has-margin-bottom-7">
            While you complete forms, your answers are saved periodically to
            avoid losing your work. If you would like to clear all of your in
            progress submissions, click the button below.
          </p>

          <span
            className="is-inline-block has-tooltip-arrow"
            data-tooltip={
              !autoSaveKeys.length
                ? 'You currently have no in progress form submissions.'
                : undefined
            }
          >
            <button
              type="button"
              className={clsx(
                'button ob-button ob-button__clear-auto-save is-primary cypress-clear-in-progress-submissions-button',
                {
                  'is-loading': isLoadingAutoSaveKeys,
                },
              )}
              disabled={!autoSaveKeys.length}
              onClick={confirmDeleteAutoSave}
            >
              Clear In Progress Submissions
            </button>
          </span>

          {errorLoadingAutoSaveKeys && (
            <ErrorMessage
              title="Error Loading Submissions"
              onTryAgain={loadAutoSaveKeysAgain}
            >
              <span className="cypress-loading-auto-save-error">
                {errorLoadingAutoSaveKeys.message}
              </span>
            </ErrorMessage>
          )}
        </div>

        <Modal
          className="cypress-confirm-delete-auto-save-modal"
          isOpen={isConfirmingDeleteAutoSave}
          title="Please Confirm"
          actions={
            <>
              <button
                type="button"
                className="button ob-button is-light cypress-cancel-delete-auto-save-button"
                onClick={cancelDeleteAutoSave}
                disabled={isDeletingAutoSave}
              >
                Cancel
              </button>
              <button
                type="button"
                className={clsx(
                  'button ob-button is-primary cypress-confirm-delete-auto-save-button',
                  {
                    'is-loading': isDeletingAutoSave,
                  },
                )}
                disabled={isDeletingAutoSave}
                onClick={handleDeleteAutoSave}
                autoFocus
              >
                Yes
              </button>
            </>
          }
        >
          <p>
            Are you sure you want to clear your in progress form submissions?
          </p>
        </Modal>

        <Modal
          className="cypress-error-modal"
          cardClassName="cypress-confirm-logout"
          isOpen={isConfirmingLogout}
          title="Confirm Logout"
          actions={
            <>
              <button
                type="button"
                className="button ob-button is-light cypress-cancel-logout-button"
                onClick={cancelLogout}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button ob-button is-primary cypress-confirm-logout-button"
                onClick={handleLogout}
                autoFocus
              >
                Logout
              </button>
            </>
          }
        >
          <p>
            Are you sure you want to logout? Auto-save data and pending form
            submissions will be cleared once you have logged out.
          </p>
        </Modal>
      </div>
    </div>
  )
}

export default React.memo(Profile)
