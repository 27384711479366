import * as React from 'react'
import { useAuth } from '@oneblink/apps-react'

import {
  LoginSignupButtons,
  RequestAccessButton,
  MaterialIcon,
} from 'components'
import useIsAuthorised from 'hooks/useIsAuthorised'

type Props = {
  label: string
  children: React.ReactNode
}

function RequiresAuthorisationSection({ label, children }: Props) {
  const isAuthorised = useIsAuthorised()
  const { isLoggedIn } = useAuth()

  // isAuthorised will be null, until it has been determined
  if (isLoggedIn && (isAuthorised === null || isAuthorised)) {
    return <section>{children}</section>
  }

  return (
    <section>
      <div className="has-text-centered has-margin-bottom-8">
        <MaterialIcon className="has-text-grey icon-x-large">lock</MaterialIcon>
      </div>
      {isLoggedIn && !isAuthorised && (
        <>
          <p className="cypress-access-denied-message ob-text__access-denied has-margin-bottom-4">
            Thank you for logging in, however, you have not been granted access
            to this application. Please request access and wait to be contacted
            by your administrator before trying again.
          </p>

          <RequestAccessButton />
        </>
      )}
      {!isLoggedIn && (
        <div className="ob-login__container">
          <p className="cypress-login-required-message ob-text__login-required has-margin-bottom-4">
            You need to be logged in to view your {label}.
          </p>

          <LoginSignupButtons />
        </div>
      )}
    </section>
  )
}

export default React.memo<Props>(RequiresAuthorisationSection)
