import * as React from 'react'
import { useHistory } from 'react-router-dom'

export default function SignUpButton({ className }: { className?: string }) {
  const history = useHistory()
  const onClick = React.useCallback(() => {
    history.push(`/sign-up`)
  }, [history])

  return (
    <>
      <button
        type="button"
        className={`button ob-button ob-button_sign-up is-secondary cypress-sign-up-button ${className}`}
        onClick={onClick}
      >
        Sign up
      </button>
    </>
  )
}
