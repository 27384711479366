// @flow
'use strict'

import * as React from 'react'
import { useLocation, Link } from 'react-router-dom'
import clsx from 'clsx'
import {
  useBooleanState,
  useClickOutsideElement,
  useAuth,
  usePendingSubmissions,
  useDrafts,
} from '@oneblink/apps-react'

import profileImage from 'images/profile.png'
import {
  MenuItem,
  menuItems as baseMenuItems,
} from 'services/menu-items-service'
import useJobs from 'hooks/useJobs'
import { MaterialIcon } from 'components'

const pwaSettings = window.formsHostnameConfiguration?.pwaSettings || null

type Props = {
  children: React.ReactNode
}

function determineIsActive(
  menuItem: Omit<MenuItem, 'badge'> & { badge?: number | null },
  pathname: string,
) {
  return pathname === '/'
    ? pathname === menuItem.href
    : pathname.startsWith(menuItem.defaultHref)
}

function BottomNavigation({ children }: Props) {
  const dropDownRef = React.useRef(null)
  const [isShowingMore, showMore, hideMore] = useBooleanState(false)

  const { pathname } = useLocation()
  const { pendingSubmissions } = usePendingSubmissions()
  const { drafts } = useDrafts()
  const { jobs } = useJobs()
  const { userFriendlyName, userProfile, isLoggedIn, isUsingFormsKey } =
    useAuth()

  const { allMenuItems, menuItems, overflowMenuItems } = React.useMemo(() => {
    const allMenuItems = baseMenuItems.map((menuItem) => {
      switch (menuItem.type) {
        case 'PENDING_SUBMISSIONS': {
          return {
            ...menuItem,
            badge: pendingSubmissions.length || null,
          }
        }
        case 'DRAFTS': {
          return {
            ...menuItem,
            badge: drafts.length || null,
          }
        }
        case 'JOBS': {
          return {
            ...menuItem,
            badge: jobs.length || null,
          }
        }
        default: {
          return menuItem
        }
      }
    })

    let menuItems = allMenuItems
    let overflowMenuItems = null
    if (menuItems.length > 5) {
      // need overflow menu
      menuItems = allMenuItems.slice(0, 4)
      overflowMenuItems = allMenuItems.slice(4)
    }
    return {
      allMenuItems,
      menuItems,
      overflowMenuItems,
    }
  }, [drafts.length, jobs.length, pendingSubmissions.length])

  useClickOutsideElement(
    dropDownRef,
    React.useCallback(() => {
      if (isShowingMore) {
        hideMore()
      }
    }, [hideMore, isShowingMore]),
  )

  const isHidingMenu = isUsingFormsKey || !allMenuItems.length

  return (
    <ob-bottom-navigation>
      <div
        className={clsx('ob-bottom-navigation', {
          'has-hidden-menu': isHidingMenu,
        })}
      >
        <div className="ob-bottom-navigation__content">{children}</div>
        <div className="ob-bottom-navigation__navbar">
          <div className="ob-bottom-navigation__buttons">
            {menuItems.map((menuItem, index) => {
              return (
                <MenuItemLink
                  key={`${index}__${menuItem.label}`}
                  href={menuItem.href}
                  className={clsx(
                    'ob-vertical-button ob-bottom-navigation__button',
                    `ob-bottom-navigation__${menuItem.classSuffix}`,
                    {
                      'is-active': determineIsActive(menuItem, pathname),
                      badge: menuItem.badge,
                    },
                  )}
                  data-badge={menuItem.badge}
                  onHide={hideMore}
                >
                  <>
                    <MaterialIcon className="ob-vertical-button__icon ob-bottom-navigation__icon">
                      {menuItem.icon}
                    </MaterialIcon>
                    <span className="ob-vertical-button__text is-ellipsis ob-bottom-navigation__text">
                      {menuItem.label}
                    </span>
                  </>
                </MenuItemLink>
              )
            })}
            {overflowMenuItems && (
              <div
                className={clsx(
                  'dropdown is-up is-right ob-vertical-button ob-bottom-navigation__drop-down',
                  {
                    'is-active': isShowingMore,
                  },
                )}
                ref={dropDownRef}
              >
                <div className="dropdown-trigger">
                  <a
                    onClick={showMore}
                    className="ob-vertical-button ob-bottom-navigation__button ob-bottom-navigation__more"
                  >
                    <MaterialIcon className="ob-bottom-navigation__icon">
                      linear_scale
                    </MaterialIcon>
                    <span className="ob-bottom-navigation__text">More</span>
                  </a>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content ob-bottom-navigation-drop-down__content">
                    {overflowMenuItems.map((menuItem, index) => {
                      return (
                        <MenuItemLink
                          key={`${index}__${menuItem.label}`}
                          href={menuItem.href}
                          className={clsx(
                            'dropdown-item ob-bottom-navigation-drop-down-menu__item',
                            {
                              'is-active': determineIsActive(
                                menuItem,
                                pathname,
                              ),
                            },
                          )}
                          onHide={hideMore}
                        >
                          <>
                            <span className="ob-bottom-navigation-drop-down-menu__icon">
                              <MaterialIcon>{menuItem.icon}</MaterialIcon>
                            </span>
                            <span className="ob-bottom-navigation-drop-down-menu__label">
                              {menuItem.label}
                            </span>
                            <span style={{ flex: 1 }}></span>
                            {menuItem.badge && (
                              <span className="tag is-primary is-rounded ob-bottom-navigation-drop-down-menu__tag">
                                {menuItem.badge}
                              </span>
                            )}
                          </>
                        </MenuItemLink>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="ob-drawer__menu">
          <div className="ob-drawer__menu-content">
            {!!pwaSettings && !!pwaSettings.homeScreenName && (
              <div>
                <div className="ob-drawer__app">
                  {!!pwaSettings.homeScreenIconUrl && (
                    <figure className="image image is-32x32 ob-drawer__app-icon">
                      <img
                        alt="Application Icon"
                        className="is-rounded"
                        src={pwaSettings.homeScreenIconUrl}
                      />
                    </figure>
                  )}
                  <h3 className="title is-3 ob-drawer__app-name">
                    {pwaSettings.homeScreenName}
                  </h3>
                </div>
                <hr className="ob-drawer__divider" />
              </div>
            )}

            {isLoggedIn && (
              <div>
                <div className="ob-drawer__profile">
                  <figure className="image is-128x128 ob-drawer__profile-figure">
                    <img
                      alt="Profile Picture"
                      className="is-rounded ob-drawer__profile-image"
                      src={(userProfile && userProfile.picture) || profileImage}
                    />
                  </figure>
                  <h5 className="title is-5 ob-drawer__profile-username">
                    {userFriendlyName}
                  </h5>
                </div>
                <hr className="ob-drawer__divider" />
              </div>
            )}

            <div className="ob-list">
              {allMenuItems.map((menuItem, index) => {
                return (
                  <MenuItemLink
                    key={`${index}__${menuItem.label}`}
                    href={menuItem.href}
                    className={clsx(
                      `ob-list__item ob-drawer__list-item ob-drawer-list-item__${menuItem.classSuffix} is-clickable`,
                      {
                        'is-active': determineIsActive(menuItem, pathname),
                      },
                    )}
                    onHide={hideMore}
                  >
                    <>
                      <div className="ob-list__avatar">
                        <MaterialIcon className="ob-list__icon ob-drawer__list-item-icon">
                          {menuItem.icon}
                        </MaterialIcon>
                      </div>
                      <div className="ob-list__content">
                        <div className="ob-list__text-primary ob-drawer__list-item-text">
                          {menuItem.label}
                        </div>
                      </div>
                      {menuItem.badge && (
                        <div className="ob-list__actions">
                          <span className="tag is-primary is-rounded ob-drawer__list-item-tag">
                            {menuItem.badge}
                          </span>
                        </div>
                      )}
                    </>
                  </MenuItemLink>
                )
              })}
            </div>

            <hr className="ob-drawer__divider" />
          </div>
        </div>
      </div>
    </ob-bottom-navigation>
  )
}

function MenuItemLink({
  href,
  onHide,
  ...props
}: React.PropsWithChildren<{
  href: string
  'data-badge'?: number | null
  className: string
  onHide: () => void
}>) {
  if (href.startsWith('/')) {
    return <Link {...props} to={href} onClick={onHide} />
  } else {
    return <a {...props} href={href} rel="noreferrer" />
  }
}

export default React.memo<Props>(BottomNavigation)
