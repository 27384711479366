// @flow
'use strict'

import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { authService } from '@oneblink/apps'
import { useBooleanState, useIsOffline } from '@oneblink/apps-react'

import { OfflineLoginModal } from 'components'

const identityProviderName =
  window.formsHostnameConfiguration?.samlIdentityProviderName || null

function LoginButton({
  className,
  isSecondary,
  autoFocus,
}: {
  className?: string
  isSecondary?: boolean
  autoFocus?: boolean
}) {
  const isOffline = useIsOffline()
  const history = useHistory()
  const location = useLocation()
  const [
    hasAttemptedLoginOffline,
    handleAttemptLoginOffline,
    clearOfflineLoginAttempt,
  ] = useBooleanState(false)
  const primaryClassName = React.useMemo(() => {
    return isSecondary ? 'is-secondary' : 'is-primary'
  }, [isSecondary])

  const handleLogin = React.useCallback(() => {
    if (identityProviderName) {
      if (isOffline) {
        handleAttemptLoginOffline()
      } else {
        authService.loginHostedUI(identityProviderName)
      }
    } else {
      history.push(`/login?continueTo=${location.pathname}${location.search}`)
    }
  }, [
    handleAttemptLoginOffline,
    history,
    isOffline,
    location.pathname,
    location.search,
  ])

  return (
    <>
      <button
        type="button"
        className={`button ob-button ob-button__login cypress-login-button ${primaryClassName} ${className}`}
        onClick={handleLogin}
        autoFocus={!!autoFocus}
      >
        Log In
      </button>
      <OfflineLoginModal
        isOpen={hasAttemptedLoginOffline}
        onClose={clearOfflineLoginAttempt}
      />
    </>
  )
}

export default React.memo(LoginButton)
